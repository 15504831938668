import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from '@emotion/styled'
import axios from 'axios'
import Recaptcha from 'react-recaptcha'
import TextareaAutosize from 'react-textarea-autosize'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import FooterComponent from '../components/footerComponent'

const ContactUsSection = styled.div`
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: black;
  }
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 878px) {
    flex-direction: column;
  }
  margin-bottom: 58px;
`

const TitleSection = styled.div`
  text-align: center;
  padding-top: 31px;
  margin-top: 15px;
  margin-bottom: 31px;
`

const Title = styled.h1`
  :before {
    content: '';
    border-bottom: 3px solid #f1b631;
    position: absolute;
    width: 21%;
    left: 40.5%;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
  }
  text-align: center;
  padding-bottom: 10px;
  padding-top: 75px;
  display: inline-block;
  position: relative;
`
const GoogleRecaptha = styled.div`
  @media only screen and (max-width: 348px) {
    transform: scale(0.75);
  }
`
const ScaledContactForm = styled.div`
  @media only screen and (max-width: 348px) {
    transform: scale(0.8);
  }
`
const ContactButton = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 3em;
  background: #f1b631;
  border: 0.5px solid #f1b631;
  box-sizing: border-box;
  border-radius: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  width: 226px;
  position: initial;
  margin-bottom: 20px;
  :hover {
    text-decoration: none;
  }
`
const ServiceH2 = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1d2a;
`

const ContactParagraph = styled.p`
  color: black;
`
const ContactUs = () => {
  const [name, setName] = React.useState(null)
  const [emailItem, setEmailItem] = React.useState(null)
  const [descr, setdescr] = React.useState(null)
  const [service, setService] = React.useState(null)
  const [showText, setShowText] = React.useState(false)
  const [buttonDisable, setButtonDisable] = React.useState(false)

  const [isVerified, setIsVerified] = React.useState(false)
  const [showCaptcha, setShowCaptcha] = React.useState(false)

  const handleName = e => {
    e.preventDefault()
    setName(e.target.value)
  }

  const handleService = e => {
    e.preventDefault()
    // setState({
    //   service: e.target.value
    // })
    setService(e.target.value)
  }

  const handleDesc = e => {
    e.preventDefault()
    // setState({
    //   desc: e.target.value
    // })
    setdescr(e.target.value)
  }

  const handleEmail = e => {
    e.preventDefault()
    // setState({
    //   email: e.target.value
    // })
    setEmailItem(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      name,
      email: emailItem,
      desc: descr,
      service
    }

    if (isVerified) {
      setShowText(!showText)

      setButtonDisable(!buttonDisable)

      submitToAPI(data)
    } else {
      alert('Please veify that you are a human!')
    }
  }

  const submitToAPI = data => {
    axios({
      method: 'post',
      url: 'https://bwv87fyolg.execute-api.us-east-2.amazonaws.com/prodution/submit-form',
      headers: {
        'Content-Type': 'application/json'
      },
      crossDomain: true,
      data: JSON.stringify(data)
    }).then(
      response => {
        console.log(response)
      },
      error => {
        console.log(error)
      }
    )
  }
  const verifyCallback = response => {
    if (response) {
      setIsVerified(true)
    }
  }
  // specifying your onload callback function
  const callback = recaptchaTken => {}
  React.useEffect(() => {
    setTimeout(() => {
      setShowCaptcha(true)
    }, 1000)
  }, [])

  return (
    <IndexLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fast Quote For Software Development</title>
        <link rel="canonical" href="http://kematixdigital.com/contact-us" />
      </Helmet>
      <Container>
        <TitleSection>
          <Title>Contact Us</Title>
        </TitleSection>{' '}
        <ContactUsSection>
          <div id="contact">
            <div className="container">
              <ScaledContactForm
                className="col-md-8"
                style={{
                  margin: 'inherit'
                }}
              >
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="section-title" style={{ textAlign: 'center' }}>
                    <ContactParagraph>
                      Please fill out the form below to get in touch with us and we will get back to you as soon as possible.
                    </ContactParagraph>
                  </div>
                  {showText && <h2>*We appreciate you contacting us. We'll get back to you soon!</h2>}
                  <form name="sentMessage" id="contactForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            style={{
                              width: '100%',
                              color: '#444',
                              position: 'relative',
                              height: '62px',
                              padding: '0 35px 0 35px',
                              display: 'block',
                              width: '100%',
                              outlineWidth: '0',
                              background: '#fff',
                              borderRadius: '31px',
                              fontSize: '18px',
                              lineHeight: '1.2',
                              color: 'rgb(68,68,68)'
                            }}
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder="Your Name"
                            onChange={handleName}
                            required
                          />
                          <p className="help-block text-danger" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            style={{
                              width: '100%',
                              color: '#444',
                              position: 'relative',
                              height: '62px',
                              padding: '0 35px 0 35px',
                              display: 'block',
                              width: '100%',
                              outlineWidth: '0',
                              background: '#fff',
                              borderRadius: '31px',
                              fontSize: '18px',
                              lineHeight: '1.2',
                              color: 'rgb(68,68,68)'
                            }}
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="Your Email"
                            onChange={handleEmail}
                            required
                          />
                          <p className="help-block text-danger" />
                        </div>
                      </div>
                      {/* (e) =>{setState({service: e.target.value})} */}
                    </div>
                    <div className="form-group">
                      <select
                        className="dropdownService"
                        style={{
                          width: '100%',
                          color: '#444',
                          position: 'relative',
                          height: '62px',
                          padding: '0 35px 0 35px',
                          display: 'block',
                          width: '100%',
                          outlineWidth: '0',
                          background: '#fff',
                          borderRadius: '31px',
                          fontSize: '18px',
                          lineHeight: '1.2',
                          color: 'rgb(117,117,117)'
                        }}
                        defaultValue=""
                        onChange={e => setService(e.target.value)}
                        required
                      >
                        <option value="" disabled hidden style={{}}>
                          Choose a Project
                        </option>
                        <option value="AI Development">AI Development</option>
                        <option value="Mobile App Development">Mobile App Development</option>
                        <option value="Custom Web App / Progressive Web App Development">
                          Custom Web App / Progressive Web App Development
                        </option>
                        <option value="Enterprise Software / API Development">Enterprise Software / API Development</option>
                        <option value="Consulting">Consulting</option>
                        <option value="Other">Other</option>
                      </select>
                      <p className="help-block text-danger" />
                    </div>
                    <div className="form-group">
                      <TextareaAutosize
                        style={{
                          width: '100%',
                          position: 'relative',
                          height: '62px',
                          fontFamily: 'arial',
                          padding: '18px 35px 15px 35px',
                          display: 'block',
                          outlineWidth: '0',
                          background: '#fff',
                          borderRadius: '31px',
                          fontSize: '18px',
                          lineHeight: '1.2',
                          color: 'rgb(68,68,68)',
                          resize: 'none'
                        }}
                        minRows={5}
                        className="form-control"
                        rows="4"
                        placeholder="Project Details"
                        onChange={handleDesc}
                        required
                      />
                      <p className="help-block text-danger" />
                    </div>
                    <div id="success" />
                    <GoogleRecaptha style={{ paddingBottom: '25px' }}>
                      {showCaptcha && (
                        <Recaptcha
                          sitekey="6LeJAZ8aAAAAAIw9gYNEMyWhCAjpiWYg35EhcPu6"
                          theme="light"
                          render="explicit"
                          verifyCallback={verifyCallback}
                          onloadCallback={callback}
                        />
                      )}
                    </GoogleRecaptha>

                    <ContactButton type="submit" disabled={buttonDisable}>
                      <ServiceH2>Send</ServiceH2>
                    </ContactButton>
                  </form>
                </div>
              </ScaledContactForm>

              <div className="col-md-12">
                <div className="row" />
              </div>
            </div>
          </div>{' '}
        </ContactUsSection>
      </Container>

      <FooterComponent />
    </IndexLayout>
  )
}

export default ContactUs
